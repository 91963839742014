import React, {useState} from "react";
import moment from "moment";
import exportFileService from "../../../services/ExportFileService";
import PopUpProjectsAdmin from "../../../components/Molecules/PopUp/PopUpProjectsAdmin";
import {Link} from "react-router-dom";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import FormSendCreditRequest from "../../../components/Molecules/Form/Engineer/FormSendCreditRequest";


export const LIST_PROJECTS_COLUMNS_RES = [
	{
		Header: "Numéro client",
		accessor: "client.reference"
	},
	{
		Header: "Nom client",
		accessor: "client.lastname",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Étape du projet",
		accessor: "niceStage",
		Cell: cellInfo => {
			return (
				<p className="step-projects-table text-micro paragraph_bold">{cellInfo.row.original.niceStage}</p>
			)
		}
	},
	{
		Header: "Date de visite",
		accessor: "visit.startAt",
		Cell: cellInfo => {
			let date = "Pas de visite"
			if (cellInfo.row.original.visit) {
				date = new Date(cellInfo.row.original.visit.startAt);
				date = moment(date).format("DD/MM/YYYY");
			}
			return date
		}
	},
	{
		Header: "Date de principe de structure",
		accessor: "study.principe",
		Cell: cellInfo => {
			let date = "Pas de PRI"
			if (cellInfo.row.original.priValidation) {
				if(cellInfo.row.original.startAt) {
					date = new Date(cellInfo.row.original.startAt);
					date = moment(date).format("DD/MM/YYYY");
				} else {
					date = "Pas de PRI"
				}
			}
			return date
		}
	},
	{
		Header: "Date de rendu",
		accessor: "study.estimatedEndAt",
		Cell: cellInfo => {
			let date = "NA"
			if (cellInfo.row.original.study) {
				if (cellInfo.row.original.study.estimatedEndAt) {
					date = new Date(cellInfo.row.original.study.estimatedEndAt);
					date = moment(date).format("DD/MM/YYYY");
				}
			}
			return date
		}
	},
	{
		Header: "Documents",
		accessor: "documents",
		Cell: cellInfo => {

			const [showPopUp, setShowPopUp] = useState(false);

			const downloadFile = () => {
				exportFileService.exportMedia(`/api/medias/${cellInfo.row.original.reportingNotice.id}`, cellInfo.row.original.reportingNotice.filename, "pdf")
			}

			const downloadFileEtudeStructure = () => {
				exportFileService.exportMedia(`/api/exports/studies/${cellInfo.row.original.study.id}`, (cellInfo.row.original.study.filename).replace(".pdf", ""), "pdf")
			}

			return (
				<>
					<div className={"documents-projects-table"}>
						{
							cellInfo.row.original.reportingNotice && (
								<div className="documents-projects-table-item" onClick={downloadFile}>
									<div className="documents-projects-table-item-image">
										<img src={"/images/pictos/open-file-white.svg"} alt={"bill"}/>
									</div>
									<div className="documents-projects-table-item-text">
										<p>Rapport de visite</p>
									</div>
								</div>
							)
						}
						{
							cellInfo.row.original.study?.status === "valid" && (
								<div onClick={cellInfo.row.original.study?.status ? downloadFileEtudeStructure : ""} className="documents-projects-table-item">
									<div className="documents-projects-table-item-image">
										<img src={"/images/pictos/bill.svg"}/>
									</div>
									<div className="documents-projects-table-item-text">
										<p>Dossier d'étude structure</p>
									</div>
								</div>
							)
						}

						{
							!cellInfo.row.original.reportingNotice && !cellInfo.row.original.study && (
								<p>N/A</p>
							)

						}

					</div>

					{
						showPopUp ? (
							<PopUpProjectsAdmin id={cellInfo.row.original.id} onClick={() => setShowPopUp(false)}/>
						) : null
					}

				</>
			)
		}
	},
	{
		Header: "Typologie projet",
		accessor: "type",
		Cell: cellInfo => {
			return (
				<div className="type-projects-table">
					<p>{cellInfo.row.original.type}</p>
				</div>
			)
		}
	},
	{
		Header: "Équipes",
		accessor: "equipe",
		Cell: cellInfo => {
			const nbTeam = []
			cellInfo.row.original.ingAff ? nbTeam.push(1) : null
			cellInfo.row.original.ingChecker ? nbTeam.push(1) : null
			cellInfo.row.original.ingDiag ? nbTeam.push(1) : null
			cellInfo.row.original.ingWriter ? nbTeam.push(1) : null

			let imageAvatar = null
			if (nbTeam.length === 1 && cellInfo.row.original.ingAff) {
				imageAvatar = <img className="card-task-people-item-content-pictures-image"
				                   src={`${process.env.REACT_APP_PROD_URL}${cellInfo.row.original.ingAff.urlAvatar ? cellInfo.row.original.ingAff.urlAvatar : ""}`}/>
			}
			if (nbTeam.length > 1) {
				imageAvatar = (
					<div className="card-task-people-item-content-pictures card-task-people-item-content-pictures-projects">
						<img className="card-task-people-item-content-pictures-image"
						     src={`${process.env.REACT_APP_PROD_URL}${cellInfo.row.original.ingAff.urlAvatar}`}/>
						<p
							className="card-task-people-item-content-pictures-nb card-task-people-item-content-pictures-image text-primary paragraph_bold color-white">
							+{(nbTeam.length) - 1}
						</p>
					</div>
				)
			}
			return imageAvatar
		}
	},
	{
		Header: "Actions",
		accessor: "demande.avoir",
		Cell: cellInfo => {
			const [showPopUp, setShowPopUp] = useState(false)
			const sendCreditRequest = async () => {
				setShowPopUp(true)

			}

			return (
				<>
					<div className={"actions-controller"}>
						<img src={"/images/pictos/blue-ask-avoir.svg"} onClick={sendCreditRequest}/>
					</div>
					{
						showPopUp ? (
							<PopUpForm onClick={() => setShowPopUp(false)}
							           large={false}
							           form={<FormSendCreditRequest
								           setShowPopUp={setShowPopUp}
								           //collaborator={collaborator}
								           id={cellInfo.row.original.id}
							           />}
							           show={showPopUp}
							/>
						) : null
					}
				</>
			)
		},

	}
]

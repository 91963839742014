import React, { useState } from "react";
import PopUpForm from "../../PopUp/PopUpForm";
import FormIngTeam from "../../Form/Engineer/FormIngTeam";
import moment from "moment";
import CardTeamUser from "./partials/CardTeamUser";

const CardTeam = ({ team, timeToRealod, dateDeVisiteEnabled, dateDeRenduEnabled }) => {
  const [showPopUp, setShowPopUp] = useState(false);

  //afficher les rôles
  const arrayRoleProject = [];
  team.ingAff ? arrayRoleProject.push("AFF") : null;
  team.ingDiag ? arrayRoleProject.push("DIAG") : null;
  team.ingChecker ? arrayRoleProject.push("VERIF") : null;
  team.ingAffExtra ? arrayRoleProject.push("AFF EXTRA") : null;
  team.ingDiagExtra ? arrayRoleProject.push("DIAG EXTRA") : null;
  team.ingCheckerExtra ? arrayRoleProject.push("VERIF EXTRA") : null;

  const rolesProjectString = arrayRoleProject.join(", ");

  //afficher les avatars
  const arrayPicturesVisit = [];
  team.ingDiag ? arrayPicturesVisit.push(team.ingDiag.urlAvatar) : null;
  team.ingDiagExtra
    ? arrayPicturesVisit.push(team.ingDiagExtra.urlAvatar)
    : null;
  const arrayPicturesDossierEtude = [];
  team.ingAff ? arrayPicturesDossierEtude.push(team.ingAff.urlAvatar) : null;
  team.ingAffExtra
    ? arrayPicturesDossierEtude.push(team.ingAffExtra.urlAvatar)
    : null;
  team.ingChecker
    ? arrayPicturesDossierEtude.push(team.ingChecker.urlAvatar)
    : null;
  team.ingCheckerExtra
    ? arrayPicturesDossierEtude.push(team.ingCheckerExtra.urlAvatar)
    : null;
  const arrayPicturesPri = [];
  team.ingAff ? arrayPicturesPri.push(team.ingAff.urlAvatar) : null;
  team.ingAffExtra ? arrayPicturesPri.push(team.ingAffExtra.urlAvatar) : null;

  return (
    <>
      <article className="card-task" onClick={(e) => setShowPopUp(true)}>
        <div className="card-task-bloc">
          <div className="card-task-intro">
            <div className="card-task-intro-text">
              <h3 className="color-primary">{`${team.address.street}, ${team.address.nicePostcode}, ${team.address.city}`}</h3>
              <p className="text-primary color-primary">
                {`${team.validQuoteServiceReferences.join(", ")}`}
              </p>
            </div>
          </div>
          <div className="card-task-people">
            <CardTeamUser
              pictures={arrayPicturesVisit}
              title={"Rapport de visite"}
            />
            <CardTeamUser
              pictures={arrayPicturesDossierEtude}
              title={"Validation du principe de structure"}
            />
            <CardTeamUser
              pictures={arrayPicturesPri}
              title={"Dossier d'étude structure"}
              link={`/dossier-etude/${team.study?.id}/redaction-du-dossier-etude`}
            />
          </div>

          <div className="card-task-content">
            <div className="card-task-content-line">
              <p className="text-primary color-primary">Nom client</p>
              <p className="text-primary color-primary paragraph_bold">
                {team.client
                  ? team.client.niceNames
                  : team.client_first_name + " " + team.client_last_name}
              </p>
            </div>
            <div className="card-task-content-line">
              <p className="text-primary color-primary">Prix du projet HT</p>
              <p className="text-primary color-primary paragraph_bold">
                {team.totalValidQuote ? team.totalValidQuote.toFixed(2) : "N/A"}
                €
              </p>
            </div>
            <div className="card-task-content-line">
              <p className="text-primary color-primary">Numéro du projet</p>
              <p className="text-primary color-primary paragraph_bold">
                {team.reference ? team.reference : null}
              </p>
            </div>
            <div className="card-task-content-line">
              <p className="text-primary color-primary">Date de visite</p>
              <p className="text-primary color-primary paragraph_bold">
              {
                team.withVisit || (!team.withVisit && (team.visit?.valid === true)) ? (
                  team.visit ? (
                    <div className="text-align-right">
                      {`${moment(team.visit.startAt).format("DD/MM/YYYY")}`}
                      <br />
                      {`${moment(team.visit.startAt).format("H:mm")}-${moment(team.visit.endAt).format("H:mm")}`}
                    </div>
                  ) : (
                    <div>
                      Réservation en cours
                    </div>
                  )
                ) : (
                  <div>
                    Pas de visite
                  </div>
                )
              }
              </p>
            </div>
            <div className="card-task-content-line">
              <p className="text-primary color-primary">
                Date du principe de structure
              </p>
              <p className="text-primary color-primary paragraph_bold">
                {team.startAt ? moment(team.startAt).format("DD/MM/YYYY") : "Pas de PRI"}
              </p>
            </div>
            <div className="card-task-content-line">
              <p className="text-primary color-primary">Date de rendu</p>
              <p className="text-primary color-primary paragraph_bold">
                {
                  !team.study.estimatedEndAt ? (
                      "N/A"
                   ) : (
                      moment(team.study.estimatedEndAt).format("DD/MM/YYYY")
                   )
                }
              </p>
            </div>
          </div>

          <div className="card-task-improvement-status">
            <p className="text-primary color-dark-grey">{team.niceStage}</p>
          </div>
        </div>
      </article>
      {showPopUp ? (
        <PopUpForm
          onClick={() => setShowPopUp(false)}
          large={true}
          title={`${team.client.niceNames} - ${team.address.street}, ${team.address.nicePostcode}, ${team.address.city}`}
          form={
            <FormIngTeam
              setShowPopUp={setShowPopUp}
              team={team}
              timeToRealod={timeToRealod}
            />
          }
          show={showPopUp}
        />
      ) : null}
    </>
  );
};

export default CardTeam;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { SchemaNewPassword } from "./Config/SchemaNewPassword";

import PasswordService from "../../../../services/PasswordService";
import { toast } from "react-toastify";

const NewPassword = () => {

  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    let password = values.password;
    let token = new URLSearchParams(window.location.search).get("reset_token");
    let data = {
      "password": password,
      "token": token
    }
    const response = await PasswordService.newPassword(data)
    if (response.response.status === 200) {
      setRequestSubmitted(true)
    } else if (response.response.status === 401) {
      toast.error(response.response.data["hydra:description"], {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const changeInputType = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }


  return (
    <div className={"section-login"}>
      <img className={"section-login-logo"} src={"/images/pictos/logo_mon_bureau_detudes_baseline.png"} alt={"Mon bureau d'études logo"} />
      <div className={"section-login-form"}>
        <div className={"forgotten-password-form"}>
          <div className={"forgotten-password-form-title"}>
            <h4>Nouveau mot de passe</h4>
          </div>

          {
            requestSubmitted ? (
              <>
                <p className="text-primary color-dark-grey info">
                  Votre mot de passe a bien été modifié. Vous pouvez dès à présent vous connecter.
                </p>
                <Link to={"/"} className={"btn-default btn-default-primary color-primary btn-default-full-width"}>
                  Se connecter
                </Link>
              </>
            ) : (
              <div className={"forgotten-password-form-container"}>
                <Formik
                  initialValues={{
                    password: "",
                    passwordConfirmation: "",
                  }}
                  validationSchema={SchemaNewPassword}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, values }) => (
                    <Form>
                      <div className={"form-row-element form-row-single-element"}>
                        <div className={"form-row-label-input form-row-element__pwd"}>
                          <label htmlFor={"password"} className={"text-micro paragraph_bold color-dark-grey"}>Nouveau mot de passe</label>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name={"password"}
                            placeholder={"Nouveau mot de passe"}
                            className={"input-text-custom-secondary"}
                          />
                          <img className="login-form__pwdIcon" src="/images/pictos/eyes-blue.svg" alt="blue gear" onClick={changeInputType} />
                          <ErrorMessage name="password" component="p" className={"text-primary color-error"} />
                        </div>
                      </div>

                      <div className={"form-row-element form-row-single-element"}>
                        <div className={"form-row-label-input"}>
                          <label htmlFor={"passwordConfirmation"} className={"text-micro paragraph_bold color-dark-grey"}>Confirmation du nouveau mot de passe</label>
                          <Field
                            type={"password"}
                            name={"passwordConfirmation"}
                            placeholder={"Confirmation du nouveau mot de passe"}
                            className={"input-text-custom-secondary"}
                          />
                          <ErrorMessage name="passwordConfirmation" component="p" className={"text-primary color-error"} />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className={"btn-default btn-default-primary color-primary btn-default-full-width"}
                      >
                        Valider
                      </button>

                    </Form>
                  )}
                </Formik>
              </div>
            )
          }


        </div>
      </div>
    </div>
  )
}

export default NewPassword;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-toastify";

import PopupValidTask from "./Popup/PopupValidTaks";
import { useAuth } from "../../../../services/useAuth";

const CardTasksInProgress = ({ task, taskValidated }) => {
  const navigate = useNavigate();
  const [taskValidatedId, setTaskValidatedId] = useState(null);
  const [taskValidatedType, setTaskValidatedType] = useState(null);
  const { role } = useAuth();

  const rolesProject = task.user.roles;
  const arrayRoleProject = [];
  rolesProject.includes("ROLE_ING_AFF") ? arrayRoleProject.push("AFF") : null;
  rolesProject.includes("ROLE_ING_DIAG") ? arrayRoleProject.push("DIAG") : null;
  rolesProject.includes("ROLE_ING_VERIF")
    ? arrayRoleProject.push("VERIF")
    : null;
  rolesProject.includes("ROLE_ING_EXTRA")
    ? arrayRoleProject.push("EXTRA")
    : null;

  const rolesProjectString = arrayRoleProject.join(", ");

  //afficher l'icone + navigate
  let iconTask = "";
  let urlNavigate = "";
  switch (task.type) {
    case "task-project-ing-study":
    case "task-project-ing-study-extra":
    case "task-project-ing-study-check":
    case "task-project-ing-study-check-extra":
    case "task-project-ing-study-fix":
    case "task-project-ing-study-fix-extra":
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "redaction-du-dossier-etude";
      break;

    case "task-project-ing-pri":
    case "task-project-ing-pri-extra":
      iconTask = "/images/pictos/tasks/task-dossier-etude.svg";
      urlNavigate = "pri";
      break;

    case "task-project-ing-visit":
    case "task-project-ing-visit-extra":
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "";
    default:
      iconTask = "/images/pictos/tasks/task-visite-compte-rendu.svg";
      urlNavigate = "";
  }

  const [showPopupValidTask, setShowPopupValidTask] = useState(false);
  const openPopupValidTask = (e) => {
    setShowPopupValidTask(!showPopupValidTask);
    setTaskValidatedType(task.type);
    setTaskValidatedId(task.id);
  };
  const closeAction = () => {
    setShowPopupValidTask(false);
    setTaskValidatedType(null);
    setTaskValidatedId(null);
  };
  //calculer l'avancement
  const startDate = moment(task.startAt)._d.getTime();
  const today = new Date().getTime();
  const endDate = moment(task.endAt)._d.getTime();
  const progressTime = ((today - startDate) / (endDate - startDate)) * 100;
  let percentAchievement = Math.round(progressTime);
  if (percentAchievement < 0) {
    percentAchievement = 0;
  }

  return (
    <>
        {(role.includes("ROLE_ING_DIAG") || role.includes("ROLE_ING_EXTRA")) && (task.type === "task-project-ing-visit" || task.type === "task-project-ing-visit-extra") ? (
          <article
            className={`card-task`}
            onClick={() => {
              if (task.status === "pending") {
                toast.info("La tâche n'est pas disponible");
              } else if (task.status === "processing") {
                if (urlNavigate !== "") {
                  navigate(`/dossier-etude/${task.studyId}/${urlNavigate}`);
                } else {
                  toast.info("La tâche n'est pas disponible");
                }
              } else {
                openPopupValidTask();
              }
            }}
          >
            <div className="card-task-bloc">
              <div className="card-task-intro">
                {/*<img src={`${task.icon_task}`} />*/}
                <img src={iconTask} />
                <div className="card-task-intro-text">
                  <h3 className="color-primary">{task.niceType}</h3>
                  <p className="text-primary color-primary">
                    {`${task.validQuoteServiceReferences.join(", ")}`}
                  </p>
                  {/* <p className="text-primary color-primary">
                  {task.project.type}{" "}
                  {rolesProjectString ? "- " + rolesProjectString : null}
                </p> */}
                </div>
              </div>

              <div className="card-task-content">
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Client</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {" "}
                    {`${task.project.client.niceGender} ${task.project.client.firstname} ${task.project.client.lastname}`}
                  </p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Numéro client</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.project.client.reference
                      ? task.project.client.reference
                      : "N/A"}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Adresse du projet</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit.address.street}, {task.visit.address.city}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Numéro de contact</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit.contactPhone
                      ? task.visit.contactPhone
                      : task.visit.client.phone}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Architecte</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.project.arch
                      ? task.project.arch.niceGender +
                      " " +
                      task.project.arch.niceNames
                      : "N/A"}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Date de la visite</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit.startAt
                      ? moment(task.visit.startAt).format("DD/MM/YYYY")
                      : null}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Créneaux de visite</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit.niceDuration}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Temps prévisionnel</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {moment
                      .utc(
                        moment
                          .duration(task.estimatedDuration, "h")
                          .asMilliseconds()
                      )
                      .format("HH[h]mm")}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">
                    Informations complémentaire
                  </p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit.contactMore
                      ? task.visit.contactMore
                      : "N/A"}
                  </p>
                </div>
              </div>

              <div className="card-task-improvement">
                <div className="card-task-improvement-percent">
                  <div className="card-task-improvement-percent-bar">
                    <div style={{ width: `${percentAchievement}` + "%" }}></div>
                  </div>
                  <p className="text-primary color-blue-jeans">{`${percentAchievement}%`}</p>
                </div>

                <div
                  className={`card-task-improvement-status ${task.status === "pending"
                      ? "red"
                      : task.status === "valid"
                        ? "vert"
                        : "orange"
                    }`}
                >
                  <p className="text-primary color-dark-grey">
                    {task.niceStatus}
                  </p>
                </div>
              </div>
            </div>
          </article>
        ) : (
          <article
            className={`card-task ${ moment(task.endAt).diff(moment(), "days") <= 2 ? "erreur" : ""}`}
            onClick={() => {
              if (task.status === "pending") {
                toast.info("La tâche n'est pas disponible");
              } else if (task.status === "processing") {
                if (urlNavigate !== "") {
                  navigate(`/dossier-etude/${task.studyId}/${urlNavigate}`);
                } else {
                  toast.info("La tâche n'est pas disponible");
                }
              } else {
                openPopupValidTask();
              }
            }}
          >
            <div className={`card-task-bloc`}>
              <div className="card-task-intro">
                {/*<img src={`${task.icon_task}`} />*/}
                <img src={iconTask} />
                <div className="card-task-intro-text">
                  <h3 className="color-primary">{task.niceType}</h3>
                  <p className="text-primary color-primary">
                    {`${task.validQuoteServiceReferences.join(", ")}`}
                  </p>
                  {/* <p className="text-primary color-primary">
                  {task.project.type}{" "}
                  {rolesProjectString ? "- " + rolesProjectString : null}
                </p> */}
                </div>
              </div>

              <div className="card-task-content">
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Client</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">{`${task.project.client.firstname} ${task.project.client.lastname}`}</p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Numéro client</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.project.client.reference}
                  </p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">
                    Ingénieur vérificateur
                  </p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.project.ingChecker
                      ? `${task.project.ingChecker?.niceGender} ${task.project.ingChecker?.niceNames}`
                      : "N/A"}
                  </p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Date de la visite</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.visit?.startAt
                      ? moment(task.visit.startAt).format("DD/MM/YYYY")
                      : "Pas de visite"}
                  </p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">
                    Date du principe de structure
                  </p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {task.project.startAt
                      ? moment(task.project.startAt).format("DD/MM/YYYY")
                      : "Pas de PRI"}
                  </p>
                </div>
                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Date de rendu</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {moment(task.studyEstimatedEndAt).format("DD/MM/YYYY")}
                  </p>
                </div>

                <div className="card-task-content-line">
                  <p className="text-primary color-primary">Temps prévisionnel</p>
                  <p className="text-primary color-primary paragraph_bold text_align_right">
                    {moment
                      .utc(
                        moment
                          .duration(task.estimatedDuration, "h")
                          .asMilliseconds()
                      )
                      .format("HH[h]mm")}
                  </p>
                </div>
              </div>

              <div className="card-task-improvement">
                <div className="card-task-improvement-percent">
                  <div className="card-task-improvement-percent-bar">
                    <div style={{ width: `${percentAchievement}` + "%" }}></div>
                  </div>
                  <p className="text-primary color-blue-jeans">{`${percentAchievement}%`}</p>
                </div>

                <div
                  className={`card-task-improvement-status ${task.status === "pending"
                      ? "red"
                      : task.status === "valid"
                        ? "vert"
                        : "orange"
                    }`}
                >
                  <p className="text-primary color-dark-grey">
                    {task.niceStatus}
                  </p>
                </div>
              </div>
            </div>
          </article>
        )}

        {/*<div className="card-task-comment">
                {task.commentaires?.map((commentaire, index) => {
                    return(
                        <div key={index} className="card-task-comment-item">
                        <p className="text-primary paragraph_bold color-primary card-task-comment-item-name">{commentaire.name}</p>
                        <p className="text-primary color-primary text-micro paragraph_bold card-task-comment-item-date">{`${commentaire.date} à ${commentaire.hour}`}</p>
                        <p className="text-primary color-primary card-task-comment-item-content">{commentaire.content}</p>
                        </div>
                        )
                    })}
                </div>*/}
      {showPopupValidTask && (
        <PopupValidTask
          showPopup={showPopupValidTask}
          closeAction={closeAction}
          taskValidatedType={taskValidatedType}
          taskValidatedId={taskValidatedId}
          taskValidated={taskValidated}
        />
      )}
    </>
  );
};

export default CardTasksInProgress;

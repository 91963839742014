import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import userService from "../../../../services/UserService";
import { initialValuesIngTeam } from "../../../../config/InitialValues/Engineer/initialValuesIngTeam";
import { ingTeamSchema } from "../../../../schemas/Engineer/IngTeamSchema";
import moment from "moment/moment";
import DirRespService from "../../../../services/Ing/DirResp/DirRespService";
import InputLabelTwoSelectErrorMessageAlex from "../../../Atomes/Formik/InputLabelTwoSelectErrorMessageAlex";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import InputLabelTwoSelectErrorMessageAlexDiag from "../../../Atomes/Formik/InputLabelTwoSelectErrorMessageAlexDiag";
import ProjectNiceType from "../../../../utils/ProjectNiceType";

const FormIngTeam = ({ setShowPopUp, team, timeToRealod }) => {
  const { addUser } = userService;

  const [showIngSupport, setShowIngSupport] = useState(false);
  const [showVerifSupport, setShowVerifSupport] = useState(false);
  const [showManagerSupport, setShowManagerSupport] = useState(false);
  const [showDiagSupport, setShowDiagSupport] = useState(false);

  const [roleExtra, setRoleExtra] = useState([]);
  const [roleDiagnostic, setRoleDiagnostic] = useState([]);
  const [roleManager, setRoleManager] = useState([]);
  const [roleVerificateur, setRoleVerificateur] = useState([]);
  const [roleChargeAffaire, setRoleChargeAffaire] = useState([]);
  const [endDateModif, setEndDateModif] = useState("N/A");

  const [location, setLocation] = useState("");

  const [loading, setLoading] = useState(true);

  const getAssignement = async () => {
    const request = await DirRespService.getAssignements(team.id);
    
    if (request.request.status === 200) {
      setShowDiagSupport(false);
      setShowIngSupport(false);
      setShowVerifSupport(false);
      setShowManagerSupport(false);
      initialValuesIngTeam.diagnostiqueurDateEnd = "";
      initialValuesIngTeam.diagnostiqueurDateStart = "";
      initialValuesIngTeam.diagnostiqueur = "";
      initialValuesIngTeam.diagnostiqueurHour = "";
      initialValuesIngTeam.diagnostiqueurSupport = "";
      initialValuesIngTeam.ingenieur = "";
      initialValuesIngTeam.ingenieurDateEnd = "";
      initialValuesIngTeam.ingenieurDateStart = "";
      initialValuesIngTeam.ingenieurHour = "";
      initialValuesIngTeam.ingenieurSupport = "";
      initialValuesIngTeam.verificateur = "";
      initialValuesIngTeam.verificateurDateEnd = "";
      initialValuesIngTeam.verificateurDateStart = "";
      initialValuesIngTeam.verificateurHour = "";
      initialValuesIngTeam.verificateurSupport = "";
      initialValuesIngTeam.manager = "";
      initialValuesIngTeam.managerDateEnd = "";
      initialValuesIngTeam.managerDateStart = "";
      initialValuesIngTeam.managerHour = "";
      initialValuesIngTeam.managerSupport = "";
      setLocation(request.data.assignments[0].region);

      request.data.assignments.map((assignement) => {
        switch (assignement.type) {
          case "task-project-ing-visit":
            if (assignement.user) {
              initialValuesIngTeam.diagnostiqueur = assignement.user.id;
              initialValuesIngTeam.diagnostiqueurDateStart =
                moment(assignement.startAt).format("yyyy-MM-DDThh:mm");
              initialValuesIngTeam.diagnostiqueurDateEnd =
                moment(assignement.endAt).format("yyyy-MM-DDThh:mm");
              if (team.visit) {
                const date1 = moment(team.visit?.startAt);
                const date2 = moment(team.visit?.endAt);
                initialValuesIngTeam.diagnostiqueurHour =
                  date2.diff(date1, "hours") + 2; // Add 2 hours of travel
              }
              break;
            } else {
              initialValuesIngTeam.diagnostiqueur = "";
              initialValuesIngTeam.diagnostiqueurDateStart = "";
              initialValuesIngTeam.diagnostiqueurDateEnd = "";
              break;
            }
          case "task-project-ing-visit-extra":
            if (assignement.user) {
              initialValuesIngTeam.diagnostiqueurSupport = assignement.user.id;
              setShowDiagSupport(true);
              break;
            } else {
              initialValuesIngTeam.diagnostiqueurSupport = "";
              setShowDiagSupport(false);
              break;
            }
          case "task-project-ing-study":
            if (assignement.user) {
              initialValuesIngTeam.ingenieur = assignement.user.id;
              initialValuesIngTeam.ingenieurDateStart =
                assignement.startAt.split("T")[0];
              initialValuesIngTeam.ingenieurDateEnd =
                assignement.endAt.split("T")[0];
              initialValuesIngTeam.ingenieurHour =
                assignement.estimatedDuration;
              break;
            } else {
              initialValuesIngTeam.ingenieur = "";
              initialValuesIngTeam.ingenieurDateStart = "";
              initialValuesIngTeam.ingenieurDateEnd = "";
              initialValuesIngTeam.ingenieurHour = "";
              break;
            }
          case "task-project-ing-study-extra":
            if (assignement.user) {
              initialValuesIngTeam.ingenieurSupport = assignement.user.id;
              setShowIngSupport(true);
              break;
            } else {
              initialValuesIngTeam.ingenieurSupport = "";
              setShowIngSupport(false);
              break;
            }
          case "task-project-ing-study-check":
            if (assignement.user) {
              initialValuesIngTeam.verificateur = assignement.user.id;
              initialValuesIngTeam.verificateurDateStart =
                assignement.startAt.split("T")[0];
              initialValuesIngTeam.verificateurDateEnd =
                assignement.endAt.split("T")[0];
              initialValuesIngTeam.verificateurHour =
                assignement.estimatedDuration;
              break;
            } else {
              initialValuesIngTeam.verificateur = "";
              initialValuesIngTeam.verificateurDateStart = "";
              initialValuesIngTeam.verificateurDateEnd = "";
              initialValuesIngTeam.verificateurHour = "";
              break;
            }
          case "task-project-ing-study-check-extra":
            if (assignement.user) {
              initialValuesIngTeam.verificateurSupport = assignement.user.id;
              setShowVerifSupport(true);
              break;
            } else {
              initialValuesIngTeam.verificateurSupport = "";
              setShowVerifSupport(false);
              break;
            }
          case "task-project-ing-manager":
            if (assignement.user) {
              initialValuesIngTeam.manager = assignement.user.id;
              initialValuesIngTeam.managerDateStart =
                assignement.startAt.split("T")[0];
              initialValuesIngTeam.managerDateEnd =
                assignement.endAt.split("T")[0];
              initialValuesIngTeam.managerHour = assignement.estimatedDuration;
              break;
            } else {
              initialValuesIngTeam.manager = "";
              initialValuesIngTeam.managerDateStart = "";
              initialValuesIngTeam.managerDateEnd = "";
              initialValuesIngTeam.managerHour = "";
              break;
            }
          case "task-project-ing-manager-extra":
            if (assignement.user) {
              initialValuesIngTeam.managerSupport = assignement.user.id;
              setShowManagerSupport(true);
              break;
            } else {
              initialValuesIngTeam.managerSupport = "";
              setShowManagerSupport(false);
              break;
            }
          default:
            break;
        }
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getAssignement();
  }, []);

  const getVerificateur = async () => {
    const request = await DirRespService.getUsersByRole({
      roles: "ROLE_ING_VERIF",
    });
    if (request.request.status === 200) {
      setRoleVerificateur(request.data["hydra:member"]);
    }
  };

  const getManager = async () => {
    const request = await DirRespService.getUsersByRole({
      roles: "ROLE_ING_MANAGER",
    });
    if (request.request.status === 200) {
      setRoleManager(request.data["hydra:member"]);
    }
  };

  const getDiagnostic = async () => {
    const request = await DirRespService.getUsersByRole({
      roles: "ROLE_ING_DIAG",
      regions: location,
    });
    if (request.request.status === 200) {
      setRoleDiagnostic(request.data["hydra:member"]);
    }
  };

  const getExtra = async () => {
    const request = await DirRespService.getUsersByRole({
      roles: "ROLE_ING_EXTRA",
    });
    if (request.request.status === 200) {
      setRoleExtra(request.data["hydra:member"]);
    }
  };

  const getAffaire = async () => {
    const request = await DirRespService.getUsersByRole({
      roles: "ROLE_ING_AFF",
    });
    if (request.request.status === 200) {
      setRoleChargeAffaire(request.data["hydra:member"]);
    }
  };

  useEffect(() => {
    getManager();
    getVerificateur();
    getDiagnostic();
    getExtra();
    getAffaire();
  }, [location]);

  const onSubmit = async (values, actions) => {
    let assignments = [
      {
        userIri: `/api/users/${values.manager}`,
        categoryType: "task-project-ing-manager",
        startAt: values.managerDateStart,
        endAt: values.managerDateEnd,
        estimatedDuration: parseFloat(values.managerHour),
      },
      {
        userIri: `/api/users/${values.ingenieur}`,
        categoryType: "task-project-ing-study",
        startAt: values.ingenieurDateStart,
        endAt: values.ingenieurDateEnd,
        estimatedDuration: parseFloat(values.ingenieurHour),
      },
      {
        userIri: `/api/users/${values.verificateur}`,
        categoryType: "task-project-ing-study-check",
        startAt: values.verificateurDateStart,
        endAt: values.verificateurDateEnd,
        estimatedDuration: parseFloat(values.verificateurHour),
      },
    ];

    if (values.diagnostiqueur !== "") {
      assignments.push({
        userIri: `/api/users/${values.diagnostiqueur}`,
        categoryType: "task-project-ing-visit",
        startAt: values.diagnostiqueurDateStart
          ? values.diagnostiqueurDateStart
          : null,
        endAt: values.diagnostiqueurDateEnd
          ? values.diagnostiqueurDateEnd
          : null,
        estimatedDuration: values.diagnostiqueurHour
          ? parseFloat(values.diagnostiqueurHour)
          : 0,
      });
    }

    if (values.diagnostiqueurSupport !== "") {
      assignments.push({
        userIri: `/api/users/${values.diagnostiqueurSupport}`,
        categoryType: "task-project-ing-visit-extra",
        startAt: values.diagnostiqueurDateStart,
        endAt: values.diagnostiqueurDateEnd,
        estimatedDuration: parseFloat(values.diagnostiqueurHour),
      });
    }

    if (values.ingenieurSupport !== "") {
      assignments.push({
        userIri: `/api/users/${values.ingenieurSupport}`,
        categoryType: "task-project-ing-study-extra",
        startAt: values.ingenieurDateStart,
        endAt: values.ingenieurDateEnd,
        estimatedDuration: parseFloat(values.ingenieurHour),
      });
    }

    if (values.verificateurSupport !== "") {
      assignments.push({
        userIri: `/api/users/${values.verificateurSupport}`,
        categoryType: "task-project-ing-study-check-extra",
        startAt: values.verificateurDateStart,
        endAt: values.verificateurDateEnd,
        estimatedDuration: parseFloat(values.verificateurHour),
      });
    }

    if (values.managerSupport !== "") {
      assignments.push({
        userIri: `/api/users/${values.managerSupport}`,
        categoryType: "task-project-ing-manager-extra",
        startAt: values.managerDateStart,
        endAt: values.managerDateEnd,
        estimatedDuration: parseFloat(values.managerHour),
      });
    }

    const request = await DirRespService.patchAssignements(team.id, {
      assignments: assignments,
    });
    if (request.request.status === 200) {
      setShowPopUp(false);
      toast.success("La tâche a été attribuée avec succès");
      timeToRealod();
    } else {
      toast.error("une erreur est survenue");
    }
  };

  useEffect(() => {
    setEndDate();
  }, [team]);

  const setEndDate = () => {
    if (team.startAt === undefined) {
      setEndDateModif("N/A");
    } else {
        if(team.study?.estimatedEndAt) {
          setEndDateModif(moment(team.study?.estimatedEndAt).format("DD/MM/YYYY"))
        }
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton height={500} />
      ) : (
        <Formik
          initialValues={initialValuesIngTeam}
          validationSchema={ingTeamSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={"pop-up-form-container-team"}>
              <div
                className={"pop-up-form-container-team-dates marginbottom-25"}
              >
                <div className="pop-up-form-container-team-dates-item">
                  <p className="text-micro paragraph_bold color-dark-grey">
                    Date de la visite
                  </p>
                  <p className="text-primary color-primary">
                    {team.visit
                      ? moment(team.visit.startAt).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>
                <div className="pop-up-form-container-team-dates-item">
                  <p className="text-micro paragraph_bold color-dark-grey">
                    Créneau de visite
                  </p>
                  <p className="text-primary color-primary">
                    {team.visit
                      ? `${moment(team.visit.startAt).format("LT")} à ${moment(
                          team.visit.endAt
                        ).format("LT")}`
                      : "N/A"}
                  </p>
                </div>
                <div className="pop-up-form-container-team-dates-item">
                  <p className="text-micro paragraph_bold color-dark-grey">
                    Validation principe structure
                  </p>
                  <p className="text-primary color-primary">
                    {team.priValidation &&
                    team.priValidation?.validation === true
                      ? moment(team.startAt).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>
                <div className="pop-up-form-container-team-dates-item">
                  <p className="text-micro paragraph_bold color-dark-grey">
                    Fin de projet estimée
                  </p>
                  <p className="text-primary color-primary">{endDateModif}</p>
                </div>
              </div>
              <div className={"form-row-element form-row-two-elements"}>
                <InputLabelTwoSelectErrorMessageAlexDiag
                  type={"select"}
                  keyLabel={"niceNames"}
                  keyValue={"id"}
                  nameRole={"diagnostiqueur"}
                  nameDateStart={"diagnostiqueurDateStart"}
                  nameDateEnd={"diagnostiqueurDateEnd"}
                  nameHour={"diagnostiqueurHour"}
                  nameSupport={"diagnostiqueurSupport"}
                  label={"Ingénieur dignostiqueur"}
                  data={roleDiagnostic}
                  labelSupport={"Ingénieur support"}
                  dataSupport={roleExtra}
                  showIngSupport={showDiagSupport}
                  withVisit={team.visitType}
                />
                <InputLabelTwoSelectErrorMessageAlex
                  type={"select"}
                  keyLabel={"niceNames"}
                  keyValue={"id"}
                  nameRole={"ingenieur"}
                  nameHour={"ingenieurHour"}
                  nameDateStart={"ingenieurDateStart"}
                  nameDateEnd={"ingenieurDateEnd"}
                  nameSupport={"ingenieurSupport"}
                  label={"Ingénieur(e) Chargé(e) d'Affaires"}
                  data={roleChargeAffaire}
                  labelSupport={"Ingénieur support"}
                  dataSupport={roleExtra}
                  showIngSupport={showIngSupport}
                />
              </div>
              <div className={"form-row-element form-row-two-elements"}>
                <InputLabelTwoSelectErrorMessageAlex
                  type={"select"}
                  keyLabel={"niceNames"}
                  keyValue={"id"}
                  nameRole={"verificateur"}
                  nameHour={"verificateurHour"}
                  nameSupport={"verificateurSupport"}
                  nameDateStart={"verificateurDateStart"}
                  nameDateEnd={"verificateurDateEnd"}
                  label={"Ingénieur vérificateur"}
                  data={roleVerificateur}
                  labelSupport={"Ingénieur support"}
                  dataSupport={roleExtra}
                  showIngSupport={showVerifSupport}
                />
                <InputLabelTwoSelectErrorMessageAlex
                  type={"select"}
                  keyLabel={"niceNames"}
                  keyValue={"id"}
                  nameRole={"manager"}
                  nameHour={"managerHour"}
                  nameDateStart={"managerDateStart"}
                  nameDateEnd={"managerDateEnd"}
                  nameSupport={"managerSupport"}
                  label={"Ingénieur manager"}
                  data={roleManager}
                  labelSupport={"Ingénieur support"}
                  dataSupport={roleExtra}
                  showIngSupport={showManagerSupport}
                />
              </div>
              <div>
                <ButtonSubmit
                  title={"Attribuer"}
                  isActive={false}
                  styleButton={"btn-default btn-default-secondary plain"}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormIngTeam;
